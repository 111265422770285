import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/page";
import React, { useEffect } from "react";
import classNames from "classnames";
import TeamSection from "./Sections/InstructorsSection";

const useStyles = makeStyles(styles);

export default function InstructorsPage(props) {
  useEffect(() => {
    document.title = "Kontury Kultury - instruktorzy"
  });
  const classes = useStyles();
  return (
    <>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <TeamSection data={props.data} />
      </div>
    </>
  )
}