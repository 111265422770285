import React from "react";

export const dateDisplay = dateTime => {
  if (dateTime == null){
    return <small>&nbsp;</small>
  }
  const date = new Date(dateTime);
  return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
};

export const timeDisplay = dateTime => {
  if (dateTime == null){
    return null
  }
  const time = new Date(dateTime);
  const trailingZero = time.getMinutes() < 10 ? '0' : '';
  if (time.getHours() === 0 && time.getMinutes() === 0) {
    return ""
  }
  return `o ${time.getHours()}:${trailingZero + time.getMinutes()}`;
};
