import { container, containerMargin, containerFluid, title } from "assets/jss/material-kit-react.js";

const page = {
  topBackground: {
    height: "300px",
    "@media (max-width: 500px)": {
      height: "200px"
    },
    maxHeight: "40vh",
    maxWidth: "1300px",
    marginTop: "90px",
    backgroundColor: "#348a8b",
    overflow: "hidden",
    marginLeft: "auto",
    marginRight: "auto",
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    backgroundColor: "#FFFFFF",
    ...container
  },
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    // marginLeft: "10vw",
    // marginRight: "10vw",
    // borderRadius: "6px",
    // boxShadow:
    //   "0 16px 24px 2px rgb(180, 38, 93, 0.14), 0 6px 30px 5px rgb(180, 38, 93, 0.12), 0 8px 10px -5px rgb(180, 38, 93, 0.2)",
    ...containerFluid,
    ...container,
    ...containerMargin,
  },
  errorIcon: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px"
  },
};

export default page;
