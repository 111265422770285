import Card from "../../components/Card/Card";
import GridItem from "../../components/Grid/GridItem";
import CardBody from "../../components/Card/CardBody";
import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/landingPageSections/teamStyle";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles(styles);

export default function TeamMemberCard(props) {
  const {photo, name, surname, role, description} = props.member;
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  return (
      <Card plain>
        <GridItem xs={6} className={classes.itemGrid}>
          <img src={photo.url} alt={`${name} ${surname}`} className={imageClasses} />
        </GridItem>
        <h3 className={classes.cardTitle}>
          {name}<br />{surname}
          <br />
        </h3>
        <h3 className={classes.spacing}><small className={classes.smallTitle}>{role}</small></h3>
        <CardBody style={{marginTop: "0px !important"}}>
          <div className={classes.description} style={{paddingLeft: "7px", paddingRight: "7px"}}>
            <ReactMarkdown renderers={{ paragraph: 'p' }} linkTarget={"_blank"}>{description}</ReactMarkdown>
          </div>
        </CardBody>
      </Card>
  )
}