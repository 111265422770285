import ky from "ky"

export default () => ky.post("https://graphql.contentful.com/content/v1/spaces/vgog0r2ptiar/environments/master",
  {
    headers: {
      "Content-Type": "application/json",
      // Authenticate the request
      Authorization: "Bearer WcLUy-EA6RscJvxDu4UkUOyuGLYVg7vGbq3iU1deYL0",
    },
    body: JSON.stringify({ query })
  })

const query = `
query {
  wystawaCollection(order: dataWernisaz_DESC, limit: 4) {
    items {
      grafika {
        url
      }
      dataWernisaz
      dataKonca
      tytul
      podtytul
      autor
      opis
    }
  }
  warsztatCollection {
    items {
      grafika {
        url
      }
      nazwa
      prowadzacy
      opis
      kiedy
      dlaKogo
      cena
      rejestracjaOtwarta
    }
  }
  zespolCollection(order: sys_firstPublishedAt_ASC) {
    items {
      photo {
        url
      }
      name
      surname
      role
      description
    }
  }
  instruktorCollection(order: order_ASC) {
    items {
      photo {
        url
      }
      order
      name
      surname
      role
      description
    }
  }
  wydarzenieCollection(order: kiedy_DESC, limit: 6) {
    items {
      grafika {
        url
      }
      tytul
      podtytul
      kiedy
      rejestracja
      tekst
      rejestracjaOtwarta
      kiedyDo
      pinned
    }
  }
  newsCollection(order: kiedy_DESC, limit: 6) {
    items {
      grafika {
        url
      }
      tytul
      podtytul
      kiedy
      tekst
      pinned
    }
  }
  aboutUs(id:"57TPGlwhmXioIpNdBDjCPH") {
    tresc
  }
  ofertaDlaBiznesu(id:"2ivFdZAufYjlqnMW3xytX1") {
    tresc
  }
  dostepnosc(id:"5a7beUr7kw6GXspXw2Con5") {
    tresc
  }
}
`
