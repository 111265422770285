import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/workshopCardStyle";
import { animated, useSpring } from "react-spring";

const useStyles = makeStyles(styles);

export default function WorkshopCard(props) {
  const { grafika, nazwa } = props.data;

  const calc = (x, y) => [ -(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.05 ]
  const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
  const [ transformant, set ] = useSpring(() => ({ xys: [ 0, 0, 1 ], config: { mass: 5, tension: 350, friction: 40 } }))

  const classes = useStyles();

  return (
    <animated.div
      className={classes.cardWrapper}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [ 0, 0, 1 ] })}
      alt={nazwa}
      style={{
        transform: transformant.xys.interpolate(trans),
      }}>
      <img
        className={classes.card}
        src={grafika.url}
        alt={nazwa}
      />
    </animated.div>
  )
}