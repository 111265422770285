/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import ContactDialog from "./ContactDialog";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const [ contactModal, setContactModal ] = React.useState(false);

  const handleClick = e => {
    e.preventDefault();
    setContactModal(true)
  };
  return (
    <footer className={footerClasses}>
      <GridContainer justify="center" className={classes.container + " " + classes.containerMargin}>
        <GridItem xs={12}>
          <div className={classes.container} style={{marginTop: 0}}>
            <div className={classes.center} style={{marginTop: 0}}>
              <GridContainer justify="center" style={{marginTop: 0}}>
                <GridItem xs={12}>
                  <div>
                    <h2>Kontury Kultury - Strefa Edukacji Twórczej</h2>
                    <p>
                      Adres: ul. Jagiellończyka 10a, 50-240 Wrocław<br/>
                      tel: 789-278-026<br/>
                      email: konturykultury.edu@gmail.com
                    </p>
                    <div className={classes.block}>
                      <GridContainer style={{maxWidth: "400px", marginLeft: "auto", marginRight: "auto", alignItems: "center"}}>
                        <GridItem xs={12} md={6}>
                          <a className={classes.footerLink} href="#" onClick={handleClick}>Pokaż dane do płatności</a>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                          <a
                            href="https://www.google.com/maps/place/Kontury+Kultury/@51.1220353,17.031182,16z/data=!4m5!3m4!1s0x0:0xa4f052779ce14a3!8m2!3d51.1217727!4d17.033478"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.footerLink}
                          >
                            Jak do nas trafić {" "}
                            <OpenInNewIcon className={classes.icon} />
                          </a>
                        </GridItem>
                      </GridContainer>

                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
          <ContactDialog open={contactModal} onClose={() => setContactModal(false)} />
        </GridItem>
        <GridItem xs={12}>
          <div className={classes.author}>
            &copy; {2021} created by{" "}
              Przemyslaw Nowosad
          </div>
        </GridItem>
      </GridContainer>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
