import Header from "./components/Header/Header";
import HeaderLeftLinks from "./components/Header/HeaderLeftLinks";
import HeaderRightLinks from "./components/Header/HeaderRightLinks";
import { Route, Switch, useLocation } from "react-router-dom";
import AboutUsPage from "./views/AboutUsPage/AboutUsPage";
import InstructorsPage from "./views/InstructorsPage/InstrutorsPage";
import LandingPage from "./views/LandingPage/LandingPage";
import Footer from "./views/Footer/Footer";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./assets/jss/material-kit-react/views/page";
import WorkshopsPage from "./views/WorkshopsPage/WorkshopsPage";
import ImageLoader from "./components/ImageLoader/ImageLoader";
import EventsPage from "./views/EventsPage/EventsPage";
import GalleryPage from "./views/GalleryPage/GalleryPage";
import BusinessPage from "./views/BusinessPage/BusinessPage";
import apis from "./apis/apis";
import Snackbar from "@material-ui/core/Snackbar";
import { dangerColor } from "./assets/jss/material-kit-react";
import _ from "lodash"
import AccessibilityPage from "./views/AccessibilityPage/AccessibilityPage";

const useStyles = makeStyles(styles);

const App = () => {
  const [ externalData, setExternalData ] = useState({});

  useEffect(() => {
    (async () => {
      const data = await apis().json().catch(err => setSnackBar(s => {
        return { ...s, open: true }
      }));
      setExternalData(data);
    })();
  }, []);
  const [ snackBar, setSnackBar ] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, open } = snackBar;

  const [pinnedEvents, unpinnedEvents] = _.partition(externalData?.data?.wydarzenieCollection.items, (item) => item?.pinned)
  const wydarzenia = _.concat(pinnedEvents, unpinnedEvents)
  const [pinned, unpinned] = _.partition(
    externalData?.data?.newsCollection.items
    .map(it => {return {...it, type: "news"}})
    .concat(externalData?.data?.wydarzenieCollection.items)
    .sort((a, b) => a.kiedy < b.kiedy ? 1 : -1)
    .map(it => it.type === "news" ? _.omit(it, "kiedy") : it),
    (item) => item?.pinned
  )
  const news = _.concat(pinned, unpinned)
  const location = useLocation();
  const classes = useStyles();

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        key={vertical + horizontal}>
        <div style={{
          fontWeight: 500,
          backgroundColor: dangerColor,
          color: "white",
          padding: "20px",
          borderRadius: "5px"
        }}>
          <i className={classes.errorIcon + " fas fa-exclamation-circle"} /> Nie udało się pobrać danych. Proszę
          zaczekaj chwilę, a później odśwież stronę.
        </div>
      </Snackbar>
      <Header
        color="transparent"
        brand="Kontury Kultury"
        leftLinks={<HeaderLeftLinks />}
        rightLinks={<HeaderRightLinks />}
        fixed
        changeColorOnScroll={{
          height: 1,
          color: "primary"
        }}
      />
      <div style={{ width: "100vw", backgroundColor: "#348a8b" }}>
        <div className={classes.topBackground}>
          <ImageLoader
            thumbnail={"/img/v3_kk_thumb.jpg"}
            fullsize={"/img/str-kk.png"}
            altText={"background"}
          />
        </div>
      </div>
      <Switch location={location}>
        <Route path="/o-nas">
          <AboutUsPage
            about={externalData?.data?.aboutUs.tresc}
            team={externalData?.data?.zespolCollection.items}
          />
        </Route>
        <Route id="instruktorzy" path="/instruktorzy">
          <InstructorsPage data={externalData?.data?.instruktorCollection.items} />
        </Route>
        <Route id="warsztaty" path="/warsztaty">
          <WorkshopsPage data={externalData?.data?.warsztatCollection.items} />
        </Route>
        <Route id="wydarzenia" path="/wydarzenia">
          <EventsPage data={wydarzenia} />
        </Route>
        <Route id="wystawy" path="/wystawy">
          <GalleryPage data={externalData?.data?.wystawaCollection.items} />
        </Route>
        <Route id="biznes" path="/biznes">
          <BusinessPage data={externalData?.data?.ofertaDlaBiznesu.tresc} />
        </Route>
        <Route id="dostepnosc" path="/dostepnosc">
          <AccessibilityPage data={externalData?.data?.dostepnosc.tresc} />
        </Route>
        <Route id="main" path="/">
          <LandingPage data={news} />
        </Route>
      </Switch>
      <Footer whiteFont />
    </>
  );
};

export default App