import React from "react"
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/galleryStyle";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CustomDialog from "../../Components/CustomDialog";
import { dateDisplay, timeDisplay } from "../../../utilFunctions";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactMarkdown from "react-markdown";
import _ from "lodash";

const useStyles = makeStyles(styles);

export default function GallerySection(props) {
  const classes = useStyles();
  const [ galleryModal, setGalleryModal ] = React.useState(false);
  const [ modalContent, setModalContent ] = React.useState(null);

  const wystawy = props.data?.map(it => {
    return { ...it, dataWernisaz: new Date(it.dataWernisaz), dataKonca: new Date(it.dataKonca) }
  })
    .sort((a, b) => b.dataWernisaz - a.dataWernisaz);

  const now = Date.now();

  const [ past, next ] = _.partition(wystawy, el => el.dataWernisaz <= now);

  const topEvent = next?.find(el => el != null) || past?.shift();

  const mainCard = (exhibition) => (
    <GridItem
      xs={12}
      style={{ flex: 1, maxWidth: "500px" }}
      className={classes.eventCardAnimation}
      tabIndex="0"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          setGalleryModal(true);
          setModalContent(exhibition)
        }
      }}
      onClick={() => {
        setGalleryModal(true);
        setModalContent(exhibition)
      }}>
      <h2 style={{ marginBottom: 0 }}>{exhibition.tytul}</h2>
      <h3
        style={{ marginTop: 0 }}>wernisaż {dateDisplay(exhibition.dataWernisaz)} {timeDisplay(exhibition.dataWernisaz)}</h3>
      <a href="#">
        <img alt={exhibition.tytul} src={exhibition.grafika.url} className={classes.eventCard} />
      </a>
    </GridItem>
  );

  const card = (exhibition) => (
    <GridItem
      xs={12}
      style={{ flex: 1 }}
      className={classes.eventCardAnimation}
      tabIndex="0"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          setGalleryModal(true);
          setModalContent(exhibition)
        }
      }}
      onClick={() => {
        setGalleryModal(true);
        setModalContent(exhibition)
      }}>
      <h3>{exhibition.tytul}</h3>
      <a href="#">
        <img alt={exhibition.tytul} src={exhibition.grafika.url} className={classes.eventCardSmall} />
      </a>
    </GridItem>
  );

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h1 className={classes.title}>Galeria Konturów</h1>
        <GridContainer justify="center">
          <GridItem xs={12} md={10}>
            <div style={{ textAlign: "justify", marginBottom: "3rem" }}><ReactMarkdown renderers={{ paragraph: 'p' }}>
              Kontury Kultury dysponują przestrzenią wystawienniczą, którą od początku działalności wykorzystują do
              prezentowania sztuki. Galeria Konturów Kultury funkcjonuje jako miejsce przyjazne i otwarte dla
              każdego twórcy, gdzie każdy może pokazać swoje prace i podzielić się artystyczną pasją. Przede
              wszystkim skupiamy się na prezentacji dzieł młodych artystów, pochodzących ze środowiska
              wrocławskiego – głównie studentów i absolwentów kierunków artystycznych, którym udostępniamy
              naszą przestrzeń. Wspieramy także artystów - pasjonatów, zajmujących się sztuką nieprofesjonalnie,
              chcących się dzielić swoimi zainteresowaniami i tworzonymi pracami. Regularnie prezentujemy również
              twórczość uczestników zajęć Konturów Kultury – grupy fotograficznej, pracowni malarskiej, czy
              multimedialnej, którzy pod okiem naszych instruktorów tworzą oryginalne i niezwykłe prace.
              Zapraszamy do współpracy wszystkich, którzy chcieliby zaprezentować swoją twórczość szerszej
              publiczności!
            </ReactMarkdown>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            {topEvent === undefined ? <CircularProgress color="secondary" /> :
              <GridContainer style={{ justifyContent: "center", marginBottom: "4rem" }}>
                {mainCard(topEvent)}
              </GridContainer>
            }
          </GridItem>
          <GridItem xs={12}>
            <h2 style={{ marginBottom: "3rem" }}>Ostatnie wystawy</h2>
            {past?.length > 0 ? (
              <GridContainer style={{ marginBottom: "3rem" }}>
                {past?.map((event, key) => (
                  <GridItem key={key} xs={12} md={6} lg={4} style={{
                    marginBottom: "15px",
                    maxWidth: "min(90%, 350px)",
                    marginRight: "auto",
                    marginLeft: "auto"
                  }}>
                    {card(event)}
                  </GridItem>
                ))}
              </GridContainer>
            ) : null
            }
          </GridItem>
        </GridContainer>
        <CustomDialog
          maxWidth={"lg"}
          open={galleryModal}
          onClose={() => setGalleryModal(false)}
        >
          {modalContent === null ? null : (
            <GridContainer justify="center">
              <GridItem xs={12}>
                <h2 className={classes.modalTitle}>
                  {modalContent.tytul}
                </h2>
                <p className={classes.modalSubtitle}>
                  {modalContent.podtytul}
                </p>
                <p className={classes.modalInfo}>
                  Autor prac: {modalContent.autor}<br />
                  Wernisaż: {dateDisplay(modalContent.dataWernisaz)} {timeDisplay(modalContent.dataWernisaz)}, wystawa
                  otwarta
                  do: {dateDisplay(modalContent.dataKonca)}
                </p>
                <div className={classes.modalDescription}>
                  <ReactMarkdown renderers={{ paragraph: 'p' }}>{modalContent.opis}</ReactMarkdown>
                </div>
              </GridItem>
            </GridContainer>
          )}
        </CustomDialog>
      </div>
    </div>
  )

}