import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";
import tooltipsStyle from "assets/jss/material-kit-react/tooltipsStyle.js";

const carouselStyle = {
  section: {
    padding: "20px 0",
    textAlign: "center"
  },
  title: {
    color: "#3C4858",
    backgroundColor: "#FFFFFF",
    fontWeight: "700",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    marginBottom: "0px !important",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  carousel: {
    height: "60px !important",
    paddingLeft: "3vh",
    paddingRight: "3vh"
  },
  carouselItem: {
    width: "100%",
    height: "60px",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    filter: "grayscale(100%)"
  },
  carouselArrows: {
    ".slick-prev:before,\n.slick-next:before": { color: "#00FF00" }
  },
  card: {
    border: "0",
    marginBottom: "20px",
    marginTop: "30px",
    borderRadius: "6px",
    color: "rgba(0, 0, 0, 0.87)",
    background: "#fff",
    width: "100%",
    // boxShadow:
    //   "0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 4px 2px -2px rgb(90, 19, 46, 0.2), 0 1px 5px 0 rgb(90, 19, 46, 0.12), 0 -1px 2px 0 rgb(90, 19, 46, 0.10)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
    transition: "all 300ms linear",
  },
  ...tooltipsStyle,
}
export default carouselStyle;