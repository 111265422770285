import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = {
  grid: {
    position: "relative",
    width: "100%",
    minHeight: "1px",
    paddingRight: "5px",
    paddingLeft: "5px",
    flexBasis: "auto"
  },
  news: {
    paddingRight: "15px",
    paddingLeft: "15px",
  }
};

const useStyles = makeStyles(styles);

export default function GridItem(props) {
  const classes = useStyles();
  const { children, className, newsItem, ...rest } = props;
  const gridClasses = classNames({
    [classes.grid]: true,
    [classes.news]: newsItem,
    [className]: className !== undefined
  })
  return (
    <Grid item {...rest} className={gridClasses}>
      {children}
    </Grid>
  );
}

GridItem.defaultProps = {
  className: ""
};

GridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
