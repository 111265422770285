import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/page";
import React, { useEffect } from "react";
import classNames from "classnames";
import BusinessSection from "./Sections/BusinessSection";

const useStyles = makeStyles(styles);

export default function BusinessPage(props) {
  useEffect(() => {
    document.title = "Kontury Kultury - oferta dla biznesu"
  });
  const classes = useStyles();
  return (
    <>
      <div className={classNames(classes.main, classes.mainRaised)}>
          <BusinessSection data={props.data}/>
      </div>
    </>
  )
}