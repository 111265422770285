import React, { useEffect, useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/aboutUsSectionStyle.js";
import ReactMarkdown from "react-markdown";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(styles);

export default function BusinessSection(props) {

  const classes = useStyles();

  const spinner = <CircularProgress color="secondary" />;
  const [ state, setState ] = useState(spinner);
  const text = props.data;
  const textRef = useRef(state);
  textRef.current = state;

  useEffect(() => {
    if (text) {
      setState(<ReactMarkdown>{text}</ReactMarkdown>)
    }
  }, [ text ]);

  setTimeout(() => {
    if (textRef.current === spinner) {
      setState(<div style={{ textAlign: "justify" }}>
        <p className={classes.description}>
          Fundację Kilo Kultury założyliśmy w lipcu 2012 roku. Specjalizujemy się w różnych dziedzinach
          artystycznych,
          a
          jednym z celów, które sobie wyznaczyliśmy, było stworzenie przestrzeni dla organizowania przedsięwzięć
          artystycznych i edukacyjnych nastawionych na aktywizację lokalnych grup społecznych — zwłaszcza
          odsuniętych
          od
          czynnego udziału w kulturze oraz grup z różnych powodów marginalizowanych.
          <br /><br />
          Miejscem, w którym fundacja działa w wyznaczonym sobie obszarze, są „Kontury Kultury” —
          ośrodek
          w
          rewitalizowanej części Nadodrza. „Kilo Kultury” zaprosiło do współpracy wrocławskich artystów i animatorów
          —
          filmowców, muzyków, plastyków, fotografów, grafików i doświadczonych instruktorów. W Ośrodku
          pracujemy
          zarówno z dziećmi, młodzieżą, jak również dorosłymi i seniorami.
          <br /><br />
          Zapraszamy do zapoznania się z naszą ofertą programową.
        </p>
      </div>)
    }
  }, 3000);
  return (
    <div className={classes.section} style={{marginBottom: "5rem"}}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <h1 className={classes.title}>Oferta dla biznesu</h1>
          {text != null ? <div style={{ textAlign: "justify" }}><ReactMarkdown renderers={{paragraph: 'p'}}>{text}</ReactMarkdown></div> : state}
        </GridItem>
      </GridContainer>
    </div>
  );
}
