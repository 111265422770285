import React from "react";
import useBlurredLoading from "../../hooks/useBlurredLoading";

const ImageLoader = props => {

  const { thumbnail, fullsize, altText } = props;
  const [ src, { isBlurred } ] = useBlurredLoading(thumbnail, fullsize);

  return (
    <div
      style={isBlurred ? {
          backgroundImage: `url(${src})`,
          filter: "blur(10px)",
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        } :
        {
          backgroundImage: `url(${src})`,
          transition: "filter 1.5s ease-out",
          overflow: "hidden",
          height: "100%",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto 300px"
        }
      }
      alt={altText} />
  )
}

export default ImageLoader