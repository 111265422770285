import React, { useEffect } from "react"
import classNames from "classnames";

import styles from "assets/jss/material-kit-react/views/page.js";
import { makeStyles } from "@material-ui/core/styles";
import GallerySection from "./Sections/GallerySection";

const useStyles = makeStyles(styles);

export default function GalleryPage(props) {
  useEffect(() => {
    document.title = "Kontury Kultury - wystawy"
  });
  const classes = useStyles();
  return (
    <>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <GallerySection data={props.data} />
      </div>
    </>
  )

}