import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/aboutUsSectionStyle.js";

const useStyles = makeStyles(styles);

export default function KiloSection() {

  const classes = useStyles();

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <h2 className={classes.title}>Kilo Kultury Fundacja</h2>
          <div style={{ textAlign: "justify" }}>
            <p>
              Operatorem Konturów Kultury jest Kilo Kultury Fundacja, której działalność skupia się na
              aktywności prospołecznej w kontekście animowania środowisk wykluczonych w obszarach
              kultury. Funkcjonowanie w ośrodku Kontury Kultury i stworzenie dla mieszkańców miejsca
              bliskiego i dostępnego bez ograniczeń wiekowych sprzyja rozwojowi naszej fundacji w
              kierunkach, które obrane zostały w początkach działania. Poza prowadzeniem Konturów Kultury
              zajmujemy się również organizacją i realizacją innych projektów, takich jak projekt "Jestem
              sobą" (ESK 2016), "Przystanek - Audiodeskrypcja" (Polski Instytut Filmowy) czy "Kolory
              dźwięku" (Kultura w sieci / Narodowe Centrum Kultury), które skierowane były dla środowisk,
              mających utrudniony dostęp do działań kulturalnych, uzupełniają naszą misję i ideę, którą
              realizujemy na co dzień w ośrodku przy ul. Jagiellończyka.
              <br/><br/>
              Kontury Kultury prowadzimy z naszym doświadczonym partnerem – Ośrodkiem Działań
              Twórczych Światowid, który wspiera nas administracyjnie.
              Całkowicie bezpłatny dostęp do wielu odbywających się u nas wydarzeń zapewnia
              dofinansowanie Gminy Wrocław.
            </p>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
