import { cardTitle, title } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";
import { primaryBoxShadow } from "../../../material-kit-react";

const eventCard = {
  cursor: "pointer",
  maxWidth: "100%",
  height: "auto",
  maxHeight: "min(500px,50vh)",
  marginRight: "auto",
  marginLeft: "auto",
  borderRadius: "5px",
  ...primaryBoxShadow
};

const modalTitle = {
  margin: "1.75rem 0 0.875rem",
  textDecoration: "none",
  fontWeight: "700",
  fontFamily: `"Roboto Slab", "Times New Roman", serif`
};
const modalSubtitle = {
  ...modalTitle,
  fontWeight: "600"
};

const galleryStyle = {
  eventCard,
  eventCardAnimation: {
    transition: "all 300ms ease-in-out",
    animationName: "$eventAnimOut",
    animationDuration: "200ms",
    animationTimingFunction: "linear",
    animationIterationCount: "1",
    animationFillMode: "forwards",
    "&:hover,&:focus,&:active": {
      animationName: "$eventAnim",
      animationDuration: "300ms",
      animationTimingFunction: "linear",
      animationIterationCount: "1",
      animationFillMode: "forwards",
      filter: "brightness(1.5)",
      transition: "all 300ms ease-in-out",
    },
  },
  eventCardSmall: {
    ...eventCard,
  },
  "@keyframes eventAnim": {
    "0%": { transform: "scale(1)" },
    "30%": { transform: "scale(0.98)" },
    "100%": { transform: "scale(1.03)" },
  },
  "@keyframes eventAnimOut": {
    "100%": { transform: "scale(1)" },
    "0%": { transform: "scale(1.03)" },
  },
  section: {
    paddingBottom: "20px",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    minHeight: "32px",
    textDecoration: "none"
  },
  modalTitle,
  modalSubtitle,
   modalInfo: {
    ...modalSubtitle,
    fontWeight: "400"
  },
  modalDescription: {
    textAlign: "justify",
    whiteSpace: "pre-line",
    overflowWrap: "break-word"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle: {
    ...cardTitle,
    margin: "1rem 0 0rem !important",
  },
  date: {
    fontStyle: "italic",
    fontSize: "85%",
    fontWeight: 400,
    marginBottom: "1rem"
  },
  description: {
    textAlign: "justify"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  margin5: {
    margin: "5px"
  }
};

export default galleryStyle;
