import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import styles from "assets/jss/material-kit-react/views/page.js";

import React, { useEffect } from "react";
import EventsSection from "./Sections/EventsSection";

const useStyles = makeStyles(styles);

export default function EventsPage(props) {
  useEffect(() => {
    document.title = "Kontury Kultury - wydarzenia"
  });
  const classes = useStyles();
  return (
    <>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <EventsSection data={props.data}/>
      </div>
    </>
  );
}
