import { cardTitle, title } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const teamStyle = {
  section: {
    paddingBottom: "20px",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle: {
    ...cardTitle,
    marginBottom: "0px"
  },
  smallTitle: {
    color: "#6c757d",
    backgroundColor: "#FFFFFF",
    marginTop: "0px",
    fontSize: "100%",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  description: {
    color: "#666",
    backgroundColor: "#FFFFFF",
    textAlign: "justify",
    marginTop: "0px !important"
  },
  cardFooter: {
    justifyContent: "center !important"
  },
  margin5: {
    margin: "5px"
  }
};

export default teamStyle;
