import {useEffect, useState} from 'react';

const useBlurredLoading = (thumbnail, fullsize) => {

  const [targetImage, setTargetImage] = useState(thumbnail);

  useEffect(() => {
    setTargetImage(thumbnail);

    const image = new Image();
    image.src = fullsize;

    image.onload = () => {
      setTargetImage(fullsize);
    };
  }, [thumbnail, fullsize]);

  return [targetImage, { isBlurred: targetImage === thumbnail }];
};

export default useBlurredLoading;
