import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/carouselStyle";
import GridContainer from "../../../components/Grid/GridContainer";
import Card from "../../../components/Card/Card";
import Carousel from "react-slick"
import GridItem from "../../../components/Grid/GridItem";
import classNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const useStyles = makeStyles(styles);

const PartnersSection = () => {
  const classes = useStyles();

  const staticPartnerImageClasses = classNames(
    classes.imgFluid,
    classes.imgPartnersStatic
  );

  const partnersUpper = [
    {
      id: "Wroclaw",
      logo: "img/partner_logos/up/logo_01.png",
      url: "https://bip.um.wroc.pl/",
      description: "Projekt finansuje"
    },
    {
      id: "Operator",
      logo: "img/partner_logos/up/logo_02.png",
      url: null,
      description: "Operator projektu"
    },
    {
      id: "Administrator",
      logo: "img/partner_logos/up/logo_03.png",
      url: "https://www.swiatowid.net.pl/",
      description: "Administrator"
    },
  ];
  const partnersLower = [
    {
      logo: "/img/partner_logos/down/logo_01.jpg",
      url: "http://lokietka5.pl/"
    },
    {
      logo: "/img/partner_logos/down/logo_02.jpg",
      url: "http://www.afa.com.pl/afa"
    },
    {
      logo: "img/partner_logos/down/logo_04.jpg",
      url: "http://www.afa.com.pl/phobos"
    },
    {
      logo: "img/partner_logos/down/logo_03.jpg",
      url: "http://www.artystycznenadodrze.pl/"
    },
    {
      logo: "img/partner_logos/down/logo_05.jpg",
      url: "https://www.mops.wroclaw.pl/"
    },
  ]

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
    initialSlide: 0,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
    ]
  };

  const partnersGrid = {
    xs: 6,
    sm: 6,
    md: 4
  };

  return (
    <div className={classes.section}>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={partnersGrid.xs} sm={partnersGrid.sm} md={partnersGrid.md}>
          <Tooltip
            title={[ partnersUpper[0].url, " ", <><br /><OpenInNewIcon /></> ]}
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <div>
              <h3 className={classes.title}>{partnersUpper[0].description}</h3>
              <a href={partnersUpper[0].url} target="_blank" rel="noopener noreferrer">
                <img src={partnersUpper[0].logo}
                     alt={partnersUpper[0].description}
                     className={staticPartnerImageClasses}
                />
              </a>
            </div>
          </Tooltip>
        </GridItem>
        <GridItem xs={partnersGrid.xs} sm={partnersGrid.sm} md={partnersGrid.md}>
          <h3 className={classes.title}>{partnersUpper[1].description}</h3>
          <img src={partnersUpper[1].logo}
               alt={partnersUpper[1].description}
               className={staticPartnerImageClasses}
          />
        </GridItem>
        <GridItem xs={partnersGrid.xs} sm={partnersGrid.sm} md={partnersGrid.md}>
          <Tooltip
            title={[ partnersUpper[2].url, " ", <><br /><OpenInNewIcon /></> ]}
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <div>
              <h3 className={classes.title}>{partnersUpper[2].description}</h3>
              <a href={partnersUpper[2].url} target="_blank" rel="noopener noreferrer">
                <img src={partnersUpper[2].logo}
                     alt={partnersUpper[2].description}
                     className={staticPartnerImageClasses}
                />
              </a>
            </div>
          </Tooltip>
        </GridItem>
      </GridContainer>
      <h3 className={classes.title} style={{ marginTop: "40px" }}>Partnerzy</h3>
      <GridContainer>
        <div className={classes.card} style={{height: "auto !important"}}>
          <Carousel className={classes.carousel} {...carouselSettings}>
            {partnersLower.map((partner, key) => {
              return (
                <div key={key}>
                  <Tooltip
                    title={[ partner.url, " ", <><br /><OpenInNewIcon /></> ]}
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div style={{
                      height: "60px !important",
                      paddingRight: "20px !important",
                      paddingLeft: "20px !important"
                    }}>
                      <a href={partner.url} target="_blank" rel="noopener noreferrer">
                        <div className={classes.carouselItem}
                             style={{ backgroundImage: `url(${partner.logo})` }}
                        />
                        {partner.url}
                      </a>
                    </div>
                  </Tooltip>
                </div>
              )
            })}
          </Carousel>
        </div>
      </GridContainer>
    </div>
  )
};

export default PartnersSection