import React, { useState } from 'react';
import { Document, Page, Outline } from "react-pdf/dist/esm/entry.webpack5";

import styles from "assets/jss/material-kit-react/views/landingPageSections/aboutUsSectionStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function PdfSection() {
  const classes = useStyles();

  const [ file, setFile ] = useState("./KonturyKultury_ETR.pdf");
  const [numPages, setNumPages] = useState(null);
  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/"
  };

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <>
      <Document
        error="Nie udało się załadować dokumentu ETR"
        noData="Nie udało się załadować dokumentu ETR"
        loading="Wczytywanie dokumentu ETR"
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
        renderMode="canvas"
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} className={classes.pdfPage} width={1500} />
        ))}
      </Document>
    </>
  );
}