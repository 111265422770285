import imagesStyles from "../../imagesStyles";

const workshopCardStyle = {
  card: {
    width: "100%",
    cursor: "pointer",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    boxShadow: "0px 10px 2vw -5px rgb(90, 19, 46, 0.3)",
    transition: "box-shadow 0.5s",
    willChange: "transform",
    border: "15px solid white",
  },
  cardBody: {
    padding: "0 0",
  },
  cardWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
    width: "100%",
  },
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  ...imagesStyles,
}

export default workshopCardStyle