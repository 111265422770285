import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/newsStyle";
import CardBody from "../../../components/Card/CardBody";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import RegularButton from "../../../components/CustomButtons/Button";
import CustomDialog from "../../Components/CustomDialog";
import SignupForm from "../../Components/SignupForm";
import GridContainer from "../../../components/Grid/GridContainer";
import { dateDisplay, timeDisplay } from "../../../utilFunctions";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles(styles);

export default function NewsCard(props) {
  const [ contactModal, setContactModal ] = React.useState(false);
  const [ moreModal, setMoreModal ] = React.useState(false);

  const { grafika, tytul, podtytul, kiedy, kiedyDo, tekst, rejestracja, rejestracjaOtwarta } = props.data;
  const classes = useStyles();
  const dateConverted = kiedy !== undefined ? new Date(kiedy) : undefined;
  const dateToConverted = kiedyDo !== undefined ? new Date(kiedyDo) : undefined;
  const eventDateInPast = new Date(kiedy) < new Date()

  let preparedText;
  if (tekst.length > 320) {
    preparedText = tekst.slice(0, 280) + resolveBeginningModifier() + "... " + '**więcej**'
  }

  function resolveBeginningModifier() {
    if (tekst.slice(0, 2) === "**") {
      return "**"
    } else if (tekst[0] === "*") {
      return "*"
    }
    return ""
  }

  return (
    <Card className={classes.newsCard} style={{ maxWidth: "500px", marginLeft: "auto", marginRight: "auto" }}>
      <GridItem xs={12} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <div className={classes.imgCardTopContainer}>
          <div style={{ backgroundImage: `url(${grafika.url})` }}
               className={classes.imgCardTopShadow} />
          <img src={grafika.url} alt={`Grafika do karty: ${tytul}`} className={classes.imgCardTop} />
        </div>
      </GridItem>
      <div className={classes.headerTitle} style={{ marginTop: "1rem" }}>
        {kiedyDo == null ? (
            <small className={classes.date}>
              {dateDisplay(dateConverted)} {timeDisplay(dateConverted)}
            </small>
          ) :
          (
            <small className={classes.date}>
              {dateDisplay(dateConverted)} {timeDisplay(dateConverted)} — {dateDisplay(dateToConverted)} {timeDisplay(dateToConverted)}
            </small>
          )
        }
        <h2 className={classes.cardTitle}>
          {tytul}
        </h2>
        <h3 className={classes.smallTitle}>
          {podtytul}
        </h3>
      </div>
      <CardBody>
        <div className={classes.description} onClick={() => setMoreModal(true)} style={{ cursor: "pointer" }}>
          <ReactMarkdown renderers={{ paragraph: 'p' }} linkTarget={"_blank"}>{preparedText ?? tekst}</ReactMarkdown>
        </div>
        {rejestracja && rejestracjaOtwarta && !eventDateInPast ? (
          <>
            <RegularButton style={{ width: "150px" }} className={classes.btnClass} color={"primary"} size={"lg"}
                           onClick={() => {
                             setMoreModal(false)
                             setContactModal(true)
                           }}>
              Weź udział!
            </RegularButton>
          </>
        ) : null}
      </CardBody>
      <CustomDialog
        fullWidth={false}
        open={contactModal}
        onClose={() => {
          setContactModal(false)
          setMoreModal(false)
        }}
      >
        <GridContainer justify="center">
          <GridItem cs={6}>
            <h2 style={{ paddingLeft: "15px", }} className={classes.title}>{tytul}</h2>
          </GridItem>
          <GridItem cs={12}>
            <Card plain style={{ paddingLeft: "15px", paddingRight: "15px", marginBottom: "0px" }}>
              <SignupForm eventType={"wydarzenia"} eventName={tytul} eventId={tytul} />
            </Card>
          </GridItem>
        </GridContainer>
      </CustomDialog>
      <CustomDialog
        fullWidth={false}
        open={moreModal}
        onClose={() => {
          setContactModal(false)
          setMoreModal(false)
        }}>
        <div>
          <ReactMarkdown renderers={{ paragraph: 'p' }} linkTarget={"_blank"}>{tekst}</ReactMarkdown>
        </div>
        {rejestracja && rejestracjaOtwarta && !eventDateInPast ? (
          <>
            <RegularButton style={{ width: "150px" }} className={classes.btnClass} color={"primary"} size={"lg"}
                           onClick={() => {
                             setMoreModal(false)
                             setContactModal(true)
                           }}>
              Weź udział!
            </RegularButton>
          </>
        ) : null}
      </CustomDialog>
    </Card>
  )
}
