import React from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TeamMemberCard from "../../Components/TeamMemberCard";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/teamStyle";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(styles);

export default function TeamSection(props) {

  const classes = useStyles();

  const teamMembers = props.data;

  return (
    <div className={classes.section}>
      <h1 className={classes.title}>Instruktorzy</h1>
      <h2>Prowadzący zajęcia w Konturach</h2>
      <div>
        {teamMembers == null ? <CircularProgress color="secondary" /> : (
          <GridContainer style={{ justifyContent: "center" }}>
            {teamMembers.map((member, key) => {
              return (
                <GridItem key={key} xs={12} sm={12} md={6} lg={4}>
                  <TeamMemberCard
                    member={member}
                  />
                </GridItem>
              )
            })}
          </GridContainer>
        )}
      </div>
    </div>
  );
}
