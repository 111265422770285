import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/modalStyle.js";
import { Grow } from "@material-ui/core";
import RegularButton from "../../components/CustomButtons/Button";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

export default function ContactDialog(props) {
  const classes = useStyles();
  const { open, onClose } = props;

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <Close className={classes.modalClose} />
        </IconButton>
        <h2 className={classes.modalTitle}>Informacje</h2>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <h3 className={classes.modalTitle}>Dane adresowe</h3>
            <p>
              Kontury Kultury - Strefa Edukacji Twórczej<br />
              Adres: 50-240 Wrocław, ul. Jagiellończyka 10a<br />
              tel:789-278-026<br />
              email: konturykultury.edu@gmail.com
            </p>
          <br />
          <h3 className={classes.modalTitle}>Dane do wpłat</h3>
            <p>
              Kilo Kultury Fundacja<br />
              54-206 Wrocław, ul. Legnicka 65<br />
              Numer konta: 72 1090 2398 0000 0001 1977 2916<br />
            </p>
        </div>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <RegularButton
          onClick={onClose}
          color="transparent"
          simple
        >
          Zamknij
        </RegularButton>
      </DialogActions>
    </Dialog>
  );
}