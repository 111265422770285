import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/page";
import React, { useEffect } from "react";
import classNames from "classnames";
import AboutUsSection from "./Sections/AboutUsSection";
import TeamSection from "./Sections/TeamSection";
import KiloSection from "./Sections/KiloSection";

const useStyles = makeStyles(styles);

export default function AboutUsPage(props) {
  useEffect(() => {
    document.title = "Kontury Kultury - o nas"
  });
  const { about, team } = props;
  const classes = useStyles();
  return (
    <>
      <div className={classNames(classes.main, classes.mainRaised)}>
          <AboutUsSection data={about} />
          <KiloSection />
          <TeamSection data={team} />
      </div>
    </>
  )
}