import React, { useEffect, useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/aboutUsSectionStyle.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles(styles);

export default function AboutUsSection(props) {

  const classes = useStyles();

  const spinner = <CircularProgress color="secondary" />;
  const [ state, setState ] = useState(spinner);
  const text = props.data;
  const textRef = useRef(state);
  textRef.current = state;

  useEffect(() => {
    if (text) {
      setState(<ReactMarkdown>{text}</ReactMarkdown>)
    }
  }, [ text ]);

  setTimeout(() => {
    if (textRef.current === spinner) {
      setState(<div style={{ textAlign: "justify" }}>
        <p>
          Kontury Kultury – Strefa Edukacji Twórczej to ośrodek kultury, który działa we Wrocławiu od
          2013 roku. Nasza przestrzeń mieści się przy ul. Jagiellończyka 10a, w zrewitalizowanym
          podwórku kamienic na Nadodrzu. W Konturach Kultury oferujemy Wam udział w warsztatach
          artystycznych, multimedialnych i ruchowych. Zajęcia kierujemy do wszystkich grup wiekowych
          od dzieci po seniorów. U nas każdy znajdzie coś dla siebie!
          <br/><br/>
          Oprócz stałych zajęć, dodatkowo zapraszamy Was na wiele innych wydarzeń towarzyszących,
          które uzupełniają ofertę warsztatów. Są to m.in.: wykłady z historii sztuki, spektakle teatralne,
          koncerty i wystawy. W nasze działania chcielibyśmy zaangażować nie tylko mieszkańców
          Nadodrza, ale także mieszkańców całego Wrocławia, aby wspólnie tworzyć i integrować nasze
          miasto.
          <br/><br/>
          Kontury Kultury to nie tylko miejsce prowadzenia warsztatów i organizowania wydarzeń
          kulturalnych, ale także strefa, w której spotykają się ludzie – miejsce poznawania się i
          spędzania czasu wolnego. W takim właśnie nieinstytucjonalnym profilu naszego ośrodka
          upatrujemy szczególną wartość. Od początku zależało nam na stworzeniu klimatu zażyłości,
          miejsca zawsze otwartego i przyjaznego każdemu. Coraz częściej uświadamiamy sobie, że
          wspólnotowość to nie tylko hasło. Działania artystyczne, niezależnie od tego, jakiej dziedziny
          sztuki dotyczą, mogą zacieśniać więzi nie tylko międzysąsiedzkie, ale także
          międzypokoleniowe.Takich właśnie dowodów zażyłości doświadczamy, prowadząc warsztaty,
          czy zwyczajnie przyjmując odwiedziny zaglądających do nas osób. Aby jeszcze mocniej wejść
          w animację środowiskową i rozwinąć model ośrodka integrującego lokalną społeczność poprzez
          kulturę, stawiamy na współpracę partnerską z innymi instytucjami, takimi jak szkoły, MOPS,
          kluby seniora, Rada Osiedla i inne.
          <br/><br/>
          Zapraszamy do zapoznania się z naszą ofertą!
        </p>
      </div>)
    }
  }, 3000);

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <h1 className={classes.title}>O nas</h1>
          {text != null ?
            <div style={{ textAlign: "justify" }}><ReactMarkdown renderers={{ paragraph: 'p' }}>{text}</ReactMarkdown>
            </div> : state}
        </GridItem>
      </GridContainer>
    </div>
  );
}
