import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import SignupForm from "../../Components/SignupForm";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/modalStyle.js";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles(styles);

export default function WorkshopDialogContent(props) {
  const classes = useStyles();
  const {
    nazwa,
    kiedy,
    opis,
    prowadzacy,
    cena,
    rejestracjaOtwarta
  } = props.data;

  return (
    <GridContainer justify="center">
      <GridItem cs={6}>
        <h2 style={{ paddingLeft: "15px", }} className={classes.title}>{nazwa}</h2>
      </GridItem>
      <GridItem cs={12} sm={12} lg={6}>
        <Card plain>
          <GridContainer>
            <GridItem xs={12} sm={3}>
              <h3 className={classes.descriptionTitle}>
                Opis zajęć:
              </h3>
            </GridItem>
            <GridItem xs={12} sm={9}>
              <div className={classes.description}>
                <ReactMarkdown renderers={{paragraph: 'p'}}>{opis}</ReactMarkdown>
              </div>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <h3 className={classes.descriptionTitle}>
                Kiedy:
              </h3>
            </GridItem>
            <GridItem xs={12} sm={9}>
              <div className={classes.description}>
                <ReactMarkdown renderers={{paragraph: 'p'}}>{kiedy}</ReactMarkdown>
              </div>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <h3 className={classes.descriptionTitle}>
                Prowadzący:
              </h3>
            </GridItem>
            <GridItem xs={12} sm={9}>
              <p className={classes.description}>
                {prowadzacy}
              </p>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <h3 className={classes.descriptionTitle}>
                Cena:
              </h3>
            </GridItem>
            <GridItem xs={12} sm={9}>
              <p className={classes.description}>
                {cena ? cena : "bezpłatne"}
              </p>
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
      <GridItem cs={12} sm={12} lg={6}>
        <Card plain style={{ paddingLeft: "15px", paddingRight: "15px", marginBottom: "0px" }}>
          {rejestracjaOtwarta ?
            (<SignupForm eventType={"warsztaty"} eventName={nazwa} eventId={nazwa} groupText={true} />)
            :
            <h3>Zapisy są obecnie zamknięte.</h3>
          }
        </Card>
      </GridItem>
    </GridContainer>
  )
}