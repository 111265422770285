import { cardTitle, title } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";
import tooltipsStyle from "../../tooltipsStyle";

const newsStyle = {
  section: {
    paddingBottom: "20px",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  smallTitle: {
    color: "#6c757d",
    backgroundColor: "#FFFFFF",
    marginTop: "10px",
    marginBottom: "0px !important"
  },
  cardTitle: {
    ...cardTitle,
  },
  newsCard: {
    "@media (max-width: 992px)": {
      marginBottom: "50px"
    },
    "@media (min-width: 992px)": {
      height: "54em",
    },
    "@media (min-width: 1200px)": {
      height: "53em",
    },
  },
  headerTitle: {
    margin: "0.5rem 0.125rem 0rem !important",
    "@media (min-width: 992px)": {
      minHeight: "11rem"
    }
  },
  date: {
    color: "#666",
    paddingTop: "5px",
    fontSize: "120%",
    fontWeight: 400,
    marginBottom: "0.75rem"
  },
  description: {
    paddingLeft: "5px",
    paddingRight: "5px",
    color: "#666",
    textAlign: "justify",
  },
  cardFooter: {
    justifyContent: "right !important",
    paddingBottom: "0 !important",
    verticalAlign: "text-bottom",
    display: "flex",
    marginBottom: "1em"
  },
  margin5: {
    margin: "5px"
  },
  btnClass: {
    "&:hover,&:focus": {
      background: "rgba(200, 44, 100, 1)"
    },
  },
  ...tooltipsStyle,
};

export default newsStyle;
