import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/modalStyle.js";
import { Grow } from "@material-ui/core";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

export default function CustomDialog(props) {
  const classes = useStyles();
  const { open, onClose, maxWidth, children } = props;

  return <Dialog
    maxWidth={maxWidth}
    classes={{
      root: classes.center,
      paper: classes.modal,
    }}
    scroll={"paper"}
    open={open}
    TransitionComponent={Transition}
    onClose={onClose}
  >
    <DialogContent
      id="classic-modal-slide-description"
      className={classes.modalBody}
      dividers
    >
      {children}
    </DialogContent>
    <DialogActions className={classes.modalFooter}>
      <Button
        onClick={onClose}
        color="danger"
        simple
      >
        Zamknij
      </Button>
    </DialogActions>
  </Dialog>;
}