const cardBodyStyle = {
  cardBody: {
    padding: "0.5rem 0.5rem",
    "@media (min-width: 576px)": {
      padding: "0.125rem 0.25rem",
    },
    flex: "1 1 auto",
    // height: "200px",
    overflow: "hidden",
  }
};

export default cardBodyStyle;
