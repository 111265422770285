import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// core components
import styles from "assets/jss/material-kit-react/views/page.js";
// Sections for this page
import PartnersSection from "./Sections/PartnersSection";
import NewsSection from "./Sections/NewsSection";

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  useEffect(() => {
    document.title = "Kontury Kultury - aktualności"
  });
  const classes = useStyles();
  return (
    <>
      <div className={classNames(classes.main, classes.mainRaised)}>
          <NewsSection data={props.data} />
          <PartnersSection />
      </div>
    </>
  );
}
