import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";

import React from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/modalStyle";

const useStyles = makeStyles(styles);

export default function SignupForm(props) {
  const { eventType, eventName, eventId, groupText } = props;
  const classes = useStyles();

  return (
    <form action="https://formsubmit.co/konturykultury.edu@gmail.com" method="POST" target="_blank">
      <input type="hidden" id="Typ" name="Typ" value={eventType} />
      <input type="hidden" id="Nazwa" name="Nazwa" value={eventName} />
      <input type="hidden" id="Identyfikator" name="Identyfikator" value={eventId} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Imię i nazwisko"
            id="name"
            name="Imię i nazwisko"
            formControlProps={{
              fullWidth: true,
              required: true
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Wiek"
            id="age"
            name="Wiek"
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Email (np. jan@kowalski.pl)"
            id="email"
            name="Email"
            formControlProps={{
              fullWidth: true,
              required: true
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Telefon (np. 123 456 789)"
            id="phone"
            name="Numer telefonu"
            formControlProps={{
              fullWidth: true,
              required: true
            }}
          />
        </GridItem>
        <CustomInput
          labelText={`Dodatkowe informacje ${groupText ? "(podaj numer preferowanej grupy)" : ""}`}
          id="message"
          name="Dodatkowe informacje"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            multiline: true,
            rows: 5
          }}
        />
        <GridItem>
          <p>Pola oznaczone * są obowiązkowe</p>
        </GridItem>
        <GridItem xs={12}>
          <Button type="submit" color="primary">Zapisz się!&nbsp;&nbsp;<OpenInNewIcon className={classes.icon} /></Button>
        </GridItem>
      </GridContainer>
    </form>
  )
}