const imagesStyles = {
  imgFluid: {
    maxWidth: "100%",
    height: "auto",
  },
  imgPartnersStatic: {
    maxHeight: "120px",
    filter: "grayscale(100%)"
  },
  imgPartnersCarousel: {
    maxWidth: "auto",
    maxHeight: "100px",
  },
  imgRounded: {
    borderRadius: "6px !important"
  },
  imgRoundedCircle: {
    borderRadius: "50% !important"
  },
  imgRaised: {
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  imgGallery: {
    width: "100%",
    marginBottom: "2.142rem"
  },
  imgCardTopContainer:{
    marginTop: "-25px",
    marginLeft: "15px",
    marginRight: "15px",
    position: "relative",
    padding: 0,
    zIndex: 1
  },
  imgCardTop: {
    width: "100%",
    aspectRatio: "4/3",
    borderRadius: "calc(.25rem - 1px)",
    // boxShadow:
    //   "0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 4px 2px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 -1px 2px 0 rgba(0, 0, 0, 0.10)",
  },
  imgCardBottom: {
    width: "100%",
    borderBottomLeftRadius: "calc(.25rem - 1px)",
    borderBottomRightRadius: "calc(.25rem - 1px)"
  },
  imgCard: {
    width: "100%",
    borderRadius: "calc(.25rem - 1px)"
  },
  imgCardOverlay: {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    padding: "1.25rem"
  }
};

export default imagesStyles;
