import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/aboutUsSectionStyle.js";
import PdfSection from "./PdfSection";
import ReactMarkdown from "react-markdown";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const useStyles = makeStyles(styles);

export default function AccessibilitySection(props) {

  const classes = useStyles();
  const text = props.data;

  return (
    <div className={classes.container}>
      <div className={classes.section} style={{ marginBottom: "5rem" }}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <h1 className={classes.title}>Dostępność</h1>

            <iframe src="https://www.youtube.com/embed/t0LoSfU8Omc" title="Wideo dostępność"
                    frameBorder="0"
                    style={{ width: "80%", aspectRatio: "16/9" }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}></iframe>
            <div style={{ textAlign: "center", marginTop: "6rem" }}>
              <a
                href="./PRZEDPRZEWODNIK_PO_KONTURACH_KULTURY.pdf"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "2rem" }}
              >
                Przedprzewodnik po Konturach Kultury <OpenInNewIcon className={classes.icon} />
              </a>
            </div>
            <PdfSection />
            <div style={{ textAlign: "justify" }}>
              <ReactMarkdown renderers={{ paragraph: 'p' }} linkTarget={"_blank"}>{text}</ReactMarkdown>
            </div>
            {/*<h2 style={{ textAlign: "left", fontWeight: "bold" }}>*/}
            {/*  Deklaracja dostępności Konturów Kultury – Strefy*/}
            {/*  Edukacji Twórczej*/}
            {/*</h2>*/}
            {/*<p style={{ textAlign: "left" }}>*/}
            {/*  Kontury Kultury – Strefa Edukacji Twórczej zobowiązuje się zapewnić*/}
            {/*  dostępność swojej strony internetowej zgodnie z przepisami ustawy z dnia*/}
            {/*  4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji*/}
            {/*  mobilnych podmiotów publicznych. Oświadczenie w sprawie dostępności ma*/}
            {/*  zastosowanie do strony internetowej www.konturykultury.art.pl*/}
            {/*  Strona internetowa jest w części zgodna z ustawą o dostępności cyfrowej stron*/}
            {/*  internetowych i aplikacji mobilnych podmiotów publicznych. Aktualnie trwają*/}
            {/*  prace nad odświeżeniem strony, która ma być dostosowana do obowiązujących*/}
            {/*  standardów.*/}
            {/*</p>*/}
            {/*<p style={{ textAlign: "left" }}>*/}
            {/*  &emsp;&#8226; Data publikacji strony internetowej: 2020/???<br />*/}
            {/*  &emsp;&#8226; Data ostatniej istotnej aktualizacji: 2022-10-<br />*/}
            {/*</p>*/}
            {/*<h3 style={{ textAlign: "left", fontWeight: "bold" }}>Status pod względem zgodności z ustawą</h3>*/}
            {/*<p style={{ textAlign: "left" }}>*/}
            {/*  Strona internetowa jest <b>częściowo zgodna</b> z ustawą o dostępności cyfrowej*/}
            {/*  stron internetowych i aplikacji mobilnych podmiotów publicznych.*/}
            {/*</p>*/}
            {/*<h4 style={{ textAlign: "left", fontWeight: "bold" }}>Przygotowanie deklaracji w sprawie dostępności</h4>*/}
            {/*<p style={{ textAlign: "left" }}>*/}
            {/*  &#8226; Deklarację sporządzono dnia: 2022-09-30<br />*/}
            {/*</p>*/}
            {/*<p style={{ textAlign: "left" }}>*/}
            {/*  Deklarację sporządzono na podstawie samooceny.*/}
            {/*</p>*/}
            {/*<h4 style={{ textAlign: "left", fontWeight: "bold" }}>Informacje zwrotne i dane kontaktowe</h4>*/}
            {/*<p style={{ textAlign: "left" }}>*/}
            {/*  &emsp;&#8226; Za rozpatrywanie uwag i wniosków odpowiada: Marek Kotula<br />*/}
            {/*  &emsp;&#8226; E-mail: biuro@konturykultury.art.pl<br />*/}
            {/*  &emsp;&#8226; Telefon: 789-278-026<br />*/}
            {/*</p>*/}
            {/*<h4 style={{ textAlign: "left", fontWeight: "bold" }}>Skargi i odwołania</h4>*/}
            {/*<p style={{ textAlign: "left" }}>*/}
            {/*  &emsp;&#8226; Organ nadzorujący: Kilo Kultury Fundacja<br />*/}
            {/*  &emsp;&#8226; Adres: Wrocław 54-206 ul. Legnicka 65<br />*/}
            {/*  &emsp;&#8226; E-mail: kilokultury.fundacja@gmail.com*/}
            {/*</p>*/}
            {/*<h3 style={{ textAlign: "left", fontWeight: "bold" }}>*/}
            {/*  Dostępność architektoniczna*/}
            {/*</h3>*/}
            {/*<p style={{ textAlign: "left" }}>*/}
            {/*  &emsp;&#8226; dojazd MPK do przystanku Paulińska: 74,15,7/ tramwaje oraz*/}
            {/*  144,132/autobusy<br />*/}
            {/*  &emsp;&#8226; dojazd MPK do przystanku pl. Staszica (obecnie Pl. Strzelecki): K, 132,*/}
            {/*  142/autobusy<br />*/}
            {/*  &emsp;&#8226; dojazd MPK do przystanku Dworzec Nadodrze: 7, 8, 15, 70,74/tramwaje<br />*/}
            {/*  &emsp;&#8226; w bezpośrednim sąsiedztwie budynku znajdują się miejsca parkingowe,*/}
            {/*  jednak nie oznaczone jako przystosowane dla osób z*/}
            {/*  niepełnosprawnością;<br />*/}
            {/*  &emsp;&#8226; do budynku prowadzi jedno wejście – główne i bezpośrednio po prawej*/}
            {/*  stronie od wejścia jest platforma do przewozu osób na wózkach<br />*/}
            {/*  &emsp;&#8226; główne wejście oraz ciągi komunikacyjne wewnątrz budynku są*/}
            {/*  zwymiarowane w sposób umożliwiający poruszanie się osobom na wózku*/}
            {/*  inwalidzkim;<br />*/}
            {/*  &emsp;&#8226; budynek wyposażony jest w platformę dostosowaną dla osób z*/}
            {/*  niepełnosprawnością;<br />*/}
            {/*  &emsp;&#8226; na poziomie -1 i 1 znajdują się toalety dostosowane do potrzeb osób z*/}
            {/*  niepełnosprawnością;<br />*/}
            {/*  &emsp;&#8226; do każdej z sal warsztatowych prowadzą szerokie wejścia, bez progów;<br />*/}
            {/*  &emsp;&#8226; możliwość wejścia do budynku Konturów Kultury z psem asystującym;<br />*/}
            {/*  &emsp;&#8226; w przestrzeni biurowej znajduje się pętla indukcyjna dla osób głuchych i z*/}
            {/*  wadami słuchu<br />*/}
            {/*  &emsp;&#8226; trwają prace nad zamieszczeniem w przestrzeni budynku specjalnego*/}
            {/*  oznakowania planu ośrodka w postaci czytelnych piktogramów<br />*/}
            {/*</p>*/}
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
