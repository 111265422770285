import { title } from "assets/jss/material-kit-react.js";

const aboutUsSectionStyle = {
  section: {
    paddingBottom: "20px",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center !important"
  },
  description: {
    color: "#777",
    backgroundColor: "#FFFFFF",
    textAlign: "justify !important",
    maxWidth: "90%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  pdfPage: {
    display: "flex",
    "& $canvas": {
      width: "100% !important",
      height: "auto !important"
    },
    "& $div": {
      display: "none"
    }
  },
  h2: {
    fontWeight: 700
  }
};

export default aboutUsSectionStyle;
