import NewsCard from "../../LandingPage/Cards/NewsCard";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/newsStyle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Masonry from "react-masonry-css";

const useStyles = makeStyles(styles);

export default function EventsSection(props) {
  const classes = useStyles();

  const events = props.data;
  const breakpointColumnsObj = {
    default: 3,
    1150: 2,
    850: 1,
  };


  return (
    <>
      <div className={classes.section}>
        <h1 className={classes.title}>Wydarzenia</h1>
        {events == null ? <CircularProgress color="secondary" /> : (
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {events.map((post, key) => {
              return (
                <div key={key}>
                  <NewsCard
                    data={post}
                  />
                </div>
              )
            })}
          </Masonry>
        )
        }
      </div>
    </>
  );
}

