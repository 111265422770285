import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/newsStyle";
import NewsCard from "../Cards/NewsCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import Masonry from "react-masonry-css";

const useStyles = makeStyles(styles);

export default function NewsSection(props) {

  const classes = useStyles();

  const posts = props.data;

  const breakpointColumnsObj = {
    default: 3,
    1250: 2,
    1000: 1,
  };

  return (
    <div className={classes.section}>
      <h1 className={classes.title}>Aktualności</h1>
      {posts == null ? (<CircularProgress color="secondary" />) :
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        { posts.map((post, key) => {
          return (
            <div key={key}>
              <NewsCard
                data={post}
              />
            </div>
          )
        })}
      </Masonry>
      }
    </div>
  );
}
