import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/page";
import React, { useEffect } from "react";
import classNames from "classnames";
import AccessibilitySection from "./Sections/AccessibilitySection";

const useStyles = makeStyles(styles);

export default function AccessibilityPage(props) {
  useEffect(() => {
    document.title = "Kontury Kultury - deklaracja dostępności"
  });
  const classes = useStyles();
  return (
    <>
      <div className={classNames(classes.main, classes.mainRaised)}>
          <AccessibilitySection data={props.data}/>
      </div>
    </>
  )
}