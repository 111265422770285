import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function HeaderLeftLinks() {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link to="/o-nas" className={classes.hideLinkColor}>
          <Button
            tabIndex="-1"
            className={classes.navLink}
            color="transparent"
          >
            O nas
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/instruktorzy" className={classes.hideLinkColor}>
          <Button
            tabIndex="-1"
            className={classes.navLink}
            color="transparent"
          >
            Instruktorzy
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/warsztaty" className={classes.hideLinkColor}>
          <Button
            tabIndex="-1"
            className={classes.navLink}
            color="transparent"
          >
            Warsztaty
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/wydarzenia" className={classes.hideLinkColor}>
          <Button
            tabIndex="-1"
            className={classes.navLink}
            color="transparent"
          >
            Wydarzenia
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/wystawy" className={classes.hideLinkColor}>
          <Button
            tabIndex="-1"
            className={classes.navLink}
            color="transparent"
          >
            Wystawy
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/biznes" className={classes.hideLinkColor}>
          <Button
            tabIndex="-1"
            className={classes.navLink}
            color="transparent"
          >
            Oferta dla biznesu
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/dostepnosc" className={classes.hideLinkColor}>
          <Button
            tabIndex="-1"
            className={classes.navLink}
            color="transparent"
          >
            Dostępność
          </Button>
        </Link>
      </ListItem>
    </List>
  );
}
