/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderRightLinks() {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Otwórz w nowym oknie"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            aria-label="open facebook"
            color="transparent"
            href="https://www.facebook.com/konturykultury"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.navLink}
          >Facebook&nbsp;
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Otwórz w nowym oknie"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            aria-label="open instagram"
            color="transparent"
            href="https://www.instagram.com/konturykultury/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.navLink}
          >Instagram &nbsp;
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="youtube-tooltip"
          title="Otwórz w nowym oknie"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            aria-label="open youtube"
            color="transparent"
            href="https://www.youtube.com/channel/UCSl3mSUvN5Hrzx4TylTeJ_g/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.navLink}
          >Youtube&nbsp;
            <i className={classes.socialIcons + " fab fa-youtube"} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
