import React from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/newsStyle";
import { faChild, faUser, faUserTie } from "@fortawesome/free-solid-svg-icons";
import WorkshopCard from "../Cards/WorkshopCard";
import NavPills from "../../../components/NavPills/NavPills";
import WorkshopDialogContent from "./WorkshopDialogContent";
import CustomDialog from "../../Components/CustomDialog";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(styles);

export default function WorkshopSection(props) {

  const classes = useStyles();

  const [ modal, setModal ] = React.useState(false);
  const [ modalContent, setModalContent ] = React.useState(null);

  const workshops = props.data;

  const tabContent = (list) => (
    <>
      {list.map((it, key) => {
        return (
          <GridItem
            tabIndex="0"
            key={key} xs={12} md={6} lg={4}
            alt={it.nazwa}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setModal(true);
                setModalContent(it)
              }
            }}
            onClick={() => {
            setModal(true);
            setModalContent(it)
          }}>
            <WorkshopCard data={it} />
          </GridItem>
        )
      })}
    </>
  );

  return (
    <div className={classes.section}>
      <h1 className={classes.title}>Warsztaty</h1>
      <h2>Kliknij w obrazek warsztatu, aby przejść do opisu i formularza zapisów.</h2>
      <GridContainer>
        <GridItem xs={12}>
          {workshops == null ? <CircularProgress color="secondary" /> :
            <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "dla seniorów",
                  tabIcon: faUser,
                  tabContent: (
                    <GridContainer style={{
                      marginLeft: "1.5vw",
                      marginRight: "1.5vw",
                      marginTop: "1.5rem",
                      marginBottom: "4rem",
                    }}>
                      {workshops == null ? null : tabContent(workshops.filter(it => it.dlaKogo === "seniorzy"))}
                    </GridContainer>
                  )
                },
                {
                  tabButton: "dla dorosłych",
                  tabIcon: faUserTie,
                  tabContent: (
                    <div>
                      <GridContainer style={{
                        marginLeft: "1rem",
                        marginRight: "1rem",
                        marginTop: "1.5rem",
                        marginBottom: "4rem",
                      }}>
                        {workshops == null ? null : tabContent(workshops.filter(it => it.dlaKogo === "dorosli"))}
                      </GridContainer>
                    </div>
                  )
                },
                {
                  tabButton: "dla dzieci i młodzieży",
                  tabIcon: faChild,
                  tabContent: (
                    <div>
                      <GridContainer style={{
                        marginLeft: "1rem",
                        marginRight: "1rem",
                        marginTop: "1.5rem",
                        marginBottom: "4rem",
                      }}>
                        {workshops == null ? null : tabContent(workshops.filter(it => it.dlaKogo === "dzieci"))}
                      </GridContainer>
                    </div>
                  )
                }
              ]}
            />
          }
        </GridItem>
      </GridContainer>
      <CustomDialog
        maxWidth={"lg"}
        open={modal}
        onClose={() => setModal(false)}
      >{modalContent === null ? null : (
        <WorkshopDialogContent data={modalContent} />
      )}
      </CustomDialog>
    </div>
  );
}
